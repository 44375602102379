/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { currencySymbols } from '@/constants/locale';
import type { Locale } from '@/types/i18n';

type SkuPricingAndAvailability = {
  lowestPrice: number;
  retailPrice: number;
};

// Decorates number or integer with $ and add two decimals
export const formatPrice = (value: string | number | null | undefined) => {
  const safeNumber = Number(value);
  if (Number.isNaN(safeNumber) || !value) {
    return '$0.00';
  }
  return `$${safeNumber.toFixed(2)}`;
};

export const getStaticFormattedPrice = (
  configurableSku = false,
  skuPricingAndAvailability: SkuPricingAndAvailability | undefined
): string => {
  let formattedPrice = 0;

  if (configurableSku) {
    formattedPrice = skuPricingAndAvailability?.lowestPrice ?? 0;
  } else {
    formattedPrice = skuPricingAndAvailability?.retailPrice ?? 0;
  }

  return formattedPrice.toFixed(2);
};

export const getFormattedPriceMultiplied = (
  currentPrice: number | string,
  multiplicator: number | string
): string => {
  const result = Number(currentPrice) * Number(multiplicator);
  return result.toFixed(2);
};

export const getCurrencySymbol = (locale: Locale) => {
  return currencySymbols[locale] || '$';
};
