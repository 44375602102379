/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const certonaPageType = {
  homePage: 'home',
  accessoriesCategoryLandingPage: 'accessorieslanding',
  partsCategoryPage: 'partscategory',
  parentCategoryPage: 'parentcategory',
  accessoriesCategoryPage: 'accessoriescategory',
  partsSubCatPage: 'partssubcat',
  accessoriesSubCatPage: 'accessoriessubcat',
  searchResultPage: 'search',
  noSearchResultPage: 'nosearch',
  partsProductPage: 'partsproduct',
  accessoriesProductPage: 'accessoriesproduct',
  accountPage: 'account',
  orderHistoryPage: 'orderhistory',
  shoppingCartPage: 'cart',
  purchaseConfirmationPage: 'purchase',
  removeFromCartEvent: 'removefromcart',
  partsLandingEvent: 'partslanding',
  pencilpdp: 'pencilpdp',
  pdp: 'pdp',
  cartAdd: 'cartadd',
  vehicleDetails: 'vehicledetails',
  dealsDrawer: 'dealsdrawer',
  recommendations: 'recommendationslanding',
} as const;

export type CertonaPageType = (typeof certonaPageType)[keyof typeof certonaPageType];
