/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import type { Dispatch, ThunkAction, GetState } from '../types';
import azURL, { getATGData, postATGData } from '../config/serviceAPI';
import { getSalesChannel } from '../utils/common';
import actionType from '../types/action';

export const fetchPaymentOptions =
  (): ThunkAction => async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    dispatch({
      type: actionType.PAYMENT_OPTION_REQUESTING,
      fedExSuccessFlag: false,
    });

    try {
      const salesChannel = getSalesChannel();
      const response = await getATGData(
        axios,
        `${azURL('paymentOptions')}?salesChannel=${salesChannel}`
      );
      dispatch({
        type: actionType.PAYMENT_OPTION_SUCCESS,
        data: response.data,
      });
    } catch (err: any) {
      dispatch({
        type: actionType.PAYMENT_OPTION_FAILURE,
        err: err.errorMessageKey,
        errorLog: err,
      });
    }
  };

export const setLayout =
  (layout: string): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SET_LAYOUT, layout });
  };

const shoprunnerTokenValidation =
  (axios: AxiosInstance, token: string): ThunkAction =>
  async (dispatch: Dispatch) => {
    try {
      const sendToken =
        token.length > 0
          ? {
              action: 'srSignIn',
              sr_token: token,
            }
          : {
              action: 'srSignOut',
              sr_token: '',
            };
      const response = await postATGData(axios, azURL('srValidation'), sendToken);
      dispatch({ type: actionType.SR_VALIDATION_SUCCESS, data: response.data });
    } catch (err: any) {
      dispatch({
        type: actionType.SR_VALIDATION_FAILURE,
        err: err.message,
        errorLog: err,
      });
    }
  };

export const validateSRToken =
  (token: string): ThunkAction =>
  (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) =>
    dispatch(shoprunnerTokenValidation(axios, token));

export const SetCardType =
  (data: any): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SET_CARD_TYPE, data });
  };

export const setPaymentStatus =
  (paymentStatus: boolean): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SET_PAYMENT_STATUS, paymentStatus });
  };

export const checkPaymentStatus =
  (checkPaymentStatus: boolean): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SET_PAYMENT_STATUS_SUCCESS, checkPaymentStatus });
  };

export const saveContinueAsGuest =
  (continueAsGuest: boolean): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SAVE_CONTINUE_AS_GUEST, continueAsGuest });
  };

export const saveCreditCardCaptchaToken =
  (googleRecaptchaToken: string): ThunkAction =>
  (dispatch: Dispatch) => {
    dispatch({ type: actionType.SAVE_GOOGLE_CAPTCHA_TOKEN, googleRecaptchaToken });
  };

export const resetCreditCardCaptchaToken = (): ThunkAction => (dispatch: Dispatch) => {
  dispatch({ type: actionType.RESET_GOOGLE_CAPTCHA_TOKEN });
};
