/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { Fragment } from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Label } from '@/features/i18n';
import { useLocale } from '@/hooks/useLocale';
import styles from './styles.module.scss';
import { formatPrice } from '@/utils/validator/formatPrice';
import { getCurrencySymbol } from '@/utils/priceHelpers';

export type PriceType =
  | 'primary'
  | 'secondary'
  | 'small'
  | 'salePrimary'
  | 'saleSecondary'
  | 'strikethrough';

type Props = {
  priceType: PriceType;
  showPlus?: boolean;
  type?: string;
  dealPrice?: Array<string> | null;
  wasDealPrice?: Array<string> | null;
  className?: string;
  wrapperClassName?: string;
  isSubTotal?: boolean;
  each?: boolean;
  corePriceValue?: number;
  miniCartFly?: boolean;
  wasVariant?: 'rightAlign';
  isConfigurableSku?: boolean;
  enableStrikeThru?: boolean;
  isCart?: boolean;
  tabIndex?: number;
  priceIconClassName?: string;
  priceClassName?: string;
  centsClassName?: string;
};

const Price = (props: Props) => {
  const {
    priceType,
    dealPrice,
    wasDealPrice,
    type,
    showPlus,
    className,
    isSubTotal,
    wrapperClassName,
    corePriceValue,
    each = false,
    wasVariant,
    isConfigurableSku,
    enableStrikeThru,
    isCart,
    tabIndex,
    priceIconClassName,
    priceClassName,
    centsClassName,
  } = props;
  const locale = useLocale();
  const currencySymbol = getCurrencySymbol(locale);

  if (!dealPrice) {
    return null;
  }
  const fullDealPrice = dealPrice.join('.').split('.');
  const primary = priceType === 'primary';
  const secondary = priceType === 'secondary';
  const small = priceType === 'small';
  const salePrimary = priceType === 'salePrimary';
  const saleSecondary = priceType === 'saleSecondary';
  const strikethrough = priceType === 'strikethrough';
  // a product can be technically discounted because of a cart level promo,
  // in that case we don't want to show the "was" pricing
  const displayWasPrice =
    wasDealPrice && (wasDealPrice[0] !== dealPrice[0] || wasDealPrice[1] !== dealPrice[1]);

  const dealPricingDollars =
    dealPrice[0].includes(',') || dealPrice[0] === '0'
      ? dealPrice[0]
      : formatPrice(Number(dealPrice[0]));
  const formattedPricingDollars =
    dealPricingDollars !== null && dealPricingDollars !== 0
      ? dealPricingDollars.split('.')
      : dealPrice[0];

  const renderWasPriceComponent = wasDealPrice && displayWasPrice && (
    <div
      className={
        wasVariant === 'rightAlign'
          ? cx(styles.wasPriceComponent, styles.wasPriceRightAlign)
          : styles.wasPriceComponent
      }
      tabIndex={tabIndex}
      data-testid="was-price-fragment"
    >
      <span className={cx(azCommonStyles['az-body-2-regular'], styles.priceAmount)}>
        {currencySymbol}
        {wasDealPrice[0]}
        {'.'}
        {wasDealPrice[1]}
      </span>
    </div>
  );

  let priceColorOverride = '';

  if (type === 'core') {
    priceColorOverride = styles.coreChargeTitleColor;
  }

  let priceAlignRight = '';

  if ((corePriceValue && corePriceValue > 0) || wasVariant) {
    priceAlignRight = styles.priceDetailAlignRight;
  }

  let renderPriceComponent = (primary || secondary || small || strikethrough) && (
    <Fragment>
      {renderWasPriceComponent}
      <div
        className={cx(
          enableStrikeThru ? styles.strikeThruPriceWrapper : styles.priceWrapper,
          isCart ? styles.cartPriceWrapper : styles.priceWrapper,
          wrapperClassName,
          priceAlignRight
        )}
      >
        <div
          className={
            enableStrikeThru
              ? styles.regularPriceStrikeThrough
              : cx(`${styles[priceType]}`, className)
          }
          data-testid="price-fragment"
        >
          <span className="sr-only">
            {isSubTotal
              ? `Subtotal is: ${dealPrice[0]} dollars and ${dealPrice[1]} cents. `
              : isConfigurableSku
              ? `Starting at: ${dealPrice[0]} dollars and ${dealPrice[1]} cents. `
              : `Total price is: ${fullDealPrice[0]} dollars and ${fullDealPrice[1]} cents.`}
          </span>
          {isConfigurableSku && (
            <span
              data-testid="cart-price-icon-deal"
              className={cx(
                isConfigurableSku ? styles.startingAt : azCommonStyles['az-title-5-heavy'],
                styles.priceIcon,
                priceColorOverride,
                styles.lowercase
              )}
              aria-hidden="true"
            >
              Starting at
            </span>
          )}
          <span
            data-testid="cart-price-icon-deal"
            className={cx(
              enableStrikeThru
                ? styles.regularPriceStrikeThrough
                : azCommonStyles['az-title-5-heavy'],
              styles.priceIcon,
              priceColorOverride,
              priceIconClassName
            )}
            aria-hidden="true"
          >
            {currencySymbol}
          </span>
          <span
            className={cx(
              enableStrikeThru
                ? styles.regularPriceStrikeThrough
                : azCommonStyles['az-title-1-heavy'],
              enableStrikeThru ? styles.priceIcon : styles.price,
              priceColorOverride,
              priceClassName
            )}
            aria-hidden="true"
          >
            {formattedPricingDollars[0]}
          </span>
          {dealPrice[1] && (
            <span
              className={cx(
                enableStrikeThru
                  ? styles.regularPriceStrikeThrough
                  : primary === true
                  ? azCommonStyles['az-title-5-heavy']
                  : azCommonStyles['az-title-1-heavy'],
                enableStrikeThru ? styles.priceIcon : styles.priceSub,
                priceColorOverride,
                centsClassName
              )}
              aria-hidden="true"
            >
              <span
                className={
                  enableStrikeThru
                    ? cx(styles.regularPriceStrikeThroughCentSymbol)
                    : styles.centSymbol
                }
              >
                .
              </span>
              {dealPrice[1]}
            </span>
          )}
        </div>
        {each && (
          <div className={styles.eachLabel}>
            <Label label="label_cart_Each" />
          </div>
        )}
      </div>
    </Fragment>
  );

  if (type == 'core') {
    let addition = '';

    if (showPlus) {
      addition = '+';
    }

    renderPriceComponent = (primary || secondary) && (
      <Fragment>
        {renderWasPriceComponent}
        <div className={styles.corePriceAlign} data-testid="price-fragment">
          {addition}
          {currencySymbol}
          {formattedPricingDollars[0]}.{dealPrice[1]}
        </div>
      </Fragment>
    );
  }

  const renderSaleComponent = (salePrimary || saleSecondary) && (
    <div className={`${styles[priceType]}`} data-testid="sale-price-fragment">
      <span className={cx(azCommonStyles['az-display-1-heavy-italic'], styles.sale)}>
        <Label label="label_Deals_Dealmaker_Sale" />{' '}
      </span>
      <span className={cx(azCommonStyles['az-title-5-medium'], styles.priceAmount)}>
        {currencySymbol}
        {formattedPricingDollars[0]}
        {'.'}
        {dealPrice[1]}
      </span>
    </div>
  );

  return (
    <Fragment>
      {renderPriceComponent}
      {renderSaleComponent}
    </Fragment>
  );
};

export default Price;
