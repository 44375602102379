/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getDeviceType } from '@/utils/getDeviceType'; // eslint-disable-line no-restricted-imports
import { certonaPageType } from '@/constants/certonaPageType';
import type { CertonaOptions } from '../interface';

export const getCertonaOptions = (certonaOptions: CertonaOptions) => {
  const {
    certonaType,
    productData,
    skuIds,
    vehicleID,
    Category1,
    subTotal,
    transactionId,
    userId,
    parttype,
    isRecItemAddedToCart = false,
  } = certonaOptions;
  switch (certonaType) {
    case certonaPageType.pdp:
      return {
        pagetype: certonaPageType.partsProductPage,
        itemid: skuIds,
        vehicleid: vehicleID ?? '',
        Category1: Category1 ?? '',
        parttype,
      };

    case certonaPageType.pencilpdp:
      return {
        pagetype: certonaPageType.pencilpdp,
        itemid: skuIds,
        vehicleid: vehicleID ?? '',
        Category1: Category1 ?? '',
        parttype,
      };

    case certonaPageType.vehicleDetails:
      return {
        pagetype: certonaPageType.vehicleDetails,
        vehicleid: vehicleID ?? '',
        deviceType: getDeviceType(),
        recommendations: true,
        isRecItemAddedToCart: isRecItemAddedToCart,
      };

    case certonaPageType.accessoriesProductPage:
      return {
        pagetype: certonaPageType.accessoriesProductPage,
        itemid: skuIds,
        vehicleid: vehicleID || '',
        Category1: Category1 || '',
        recommendations: true,
        parttype,
        deviceType: getDeviceType(),
      };
    case certonaPageType.homePage:
      return {
        pagetype: certonaPageType.homePage,
        vehicleid: vehicleID,
      };
    case certonaPageType.purchaseConfirmationPage: {
      const productQuantityList = productData?.map((product) => product.itemQuantity) || [];
      const productIdList =
        productData?.map((product) => product.productInfo.skuId).join(';') || '';
      const price = productQuantityList
        .map((quantity, index) => productData?.[index].lineItemPriceInfo.retailPrice.toString())
        .join(';');
      const productQuantityListString = productQuantityList.join(';');

      return {
        pagetype: certonaPageType.purchaseConfirmationPage,
        recommendations: true,
        itemid: productIdList,
        qty: productQuantityListString,
        price,
        total: subTotal,
        transactionid: transactionId,
        customerid: userId,
      };
    }
    case certonaPageType.orderHistoryPage:
      return {
        pagetype: certonaPageType.orderHistoryPage,
      };
    case certonaPageType.searchResultPage:
      return {
        pagetype: certonaPageType.searchResultPage,
        recommendations: false,
      };
    case certonaPageType.accountPage:
      return {
        pagetype: certonaPageType.accountPage,
        recommendations: false,
        customerid: userId,
      };
    case certonaPageType.partsCategoryPage:
    case certonaPageType.partsSubCatPage:
    case certonaPageType.accessoriesCategoryPage:
    case certonaPageType.accessoriesSubCatPage:
    case certonaPageType.parentCategoryPage:
      return {
        pagetype: certonaType,
        vehicleid: vehicleID,
        Category1,
      };
    case certonaPageType.partsLandingEvent:
    case certonaPageType.accessoriesCategoryLandingPage: {
      return {
        itemid: skuIds,
        pagetype: certonaType,
        vehicleid: vehicleID,
        Category1,
        recommendations: true,
        parttype,
      };
    }
    case certonaPageType.noSearchResultPage: {
      return {
        pagetype: certonaPageType.noSearchResultPage,
        vehicleid: vehicleID,
        recommendations: true,
        deviceType: getDeviceType(),
        itemid: skuIds,
      };
    }
    case certonaPageType.shoppingCartPage: {
      return {
        pagetype: certonaPageType.shoppingCartPage,
        itemid: skuIds,
        vehicleid: vehicleID,
        customerid: userId,
        isRecItemAddedToCart: isRecItemAddedToCart,
        parttype,
      };
    }
    case certonaPageType.cartAdd: {
      return {
        pagetype: certonaPageType.cartAdd,
        itemid: skuIds,
        vehicleid: vehicleID,
        isRecItemAddedToCart: isRecItemAddedToCart,
        parttype,
      };
    }
    case certonaPageType.dealsDrawer:
      return {
        pagetype: certonaPageType.dealsDrawer,
        itemid: skuIds ?? '',
        vehicleid: vehicleID ?? '',
        isRecItemAddedToCart: isRecItemAddedToCart,
      };
    case certonaPageType.removeFromCartEvent: {
      return {
        pagetype: certonaPageType.removeFromCartEvent,
        itemid: skuIds,
      };
    }
    case certonaPageType.recommendations: {
      return {
        pagetype: certonaPageType.recommendations,
        vehicleid: vehicleID,
        recommendations: true,
      };
    }
    default:
      return {
        pagetype: '',
      };
  }
};
