/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const pageTypes = {
  brandShelfPremium: 'Brand Profile Premium',
  brandShelf: 'Brand Profile',
  brandsMain: 'Brands Main',
  facetedNav: 'faceted_nav',
  home: 'home',
  landingPage: 'landing-page',
  /*
       Because shopByMake and shopByModel have the same type,
       we need some way of distinguishing between them.
     */
  shopBy_: 'catalog_ymm-mm',
  shopByMake: 'shopbymake_type',
  shopByModel: 'shopbymodel_type',
  pdp: 'catalog_product-detail',
  productDetails: 'productDetails',
  catalog: 'catalog',
  catalogSubcategory: 'catalog_subcategory',
  catalogSection: 'catalog_section',
  catalogCategory: 'catalog_category',
  shopByMakeCategory: 'shopbymake_category',
  shopByModelCategory: 'shopbymodel_category',
  catalogSearchResults: 'catalog_search-results',
  catalogNewSearchResults: 'search_results_shelf',
  createAccount: 'myzone_create-account',
  myAccount: 'my-account',
  productShelf: 'catalog_shelf',
  contactUs: 'customer-service_contact-us',
  giftCard: 'gift_card',
  coupons: 'coupons',
  deals: 'deals',
  rebates: 'rebates',
  circular: 'circular',
  trackOrder: 'customer-service_track-order',
  cart: 'cart',
  orderConfirmation: 'order-confirmation',
  orderHistory: 'myzone_order-history',
  orderHistoryDetails: 'myzone_order-history-details',
  notFound: '404',
  underConstruction: 'construction',
  navigation: 'navigation',
  subcategory: 'subcategory',
  makeNav: 'shopbymake_nav',
  makeCategory: 'make_category',
  makeSubCategory: 'make_subcategory',
  makePartType: 'make_part_type',
  modelNav: 'shopbymodel_nav',
  modelCategory: 'model_category',
  modelSubCategory: 'model_subcategory',
  modelPartType: 'model_part_type',
  myVehicles: 'myzone_vehicles',
  yearMakeModelNav: 'year_make_model_nav',
  yearMakeModelCategory: 'year_make_model_category',
  yearMakeModelSubCategory: 'year_make_model_subcategory',
  yearMakeModelPartType: 'year_make_model_part_type',
  sms: 'sms-signup',
  resetPassword: 'reset-password',
  requestCredit: 'myzone_rewards',
  sitemap: 'sitemap',
  signIn: 'signIn',
  myOrders: 'myzone_order-history_orders',
  reviews: 'reviews',
} as const;
