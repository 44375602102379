/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import environment from '@/config/environment';

export function getCitrusAPIKey(forceProd: boolean) {
  return environment.TARGET_ENV === 'production' || forceProd
    ? 'sk_4seHT0r4efCzaNZjIyhCdM0jjr5lNDk3Y2Y5Ni05OWNhLTQ0MDktOTk2Ni02MDVmMjgzYjhlZDY='
    : 'sk_sbkK0KYao_E-U1a4Y3uy9oZMvYs1YWU5ZWViNS1hNjdlLTQzOTMtOGI1ZS0xNTU5Yzg5N2EwODE=';
}
