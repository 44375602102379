/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const fulfillmentLabels = {
  // Common Use mix n match
  label_shipping_OrderBefore: 'label_shipping_OrderBy', // replace with label_shipping_cutoff_ampm_head
  label_shipping_For: 'label_checkout_shipping_For',
  label_shipping_arrives: 'label_shipping_arrives', // replace with label_shipping_cutoff_ampm_tail
  labels_order_receive: 'labels_order_receive', //replace with label_shipping_cutoff_hrsmin_tail
  label_shipping_Delivery: 'label_checkout_shipping_Delivery',
  label_shipping_within: 'labels_order_within', // replace with label_shipping_cutoff_hrsmin_head
  label_shipping_to: 'labels_order_to',
  label_shipping_hrs: 'labels_order_hrs',
  label_shipping_min: 'labels_order_min',
  label_shipping_and: 'labels_order_and',
  label_shipping_Today: 'label_shipping_Today',
  label_shipping_Tomorrow: 'label_shipping_Tomorrow',
  label_shipping_tomorrow_lowercase: 'label_fulfilment_options_tomorrow',
  lblOrderBy10PM: 'label_order_by_10PM',
  label_shipping_Deliver: 'label_shipping_Deliver',
  label_inStock_sisterStores: 'Label_InStock_SisterStores',
  label_sdd: 'label_sdd',
  label_shipping_next_day_delivery: 'label_shipping_next_day_delivery',
  label_shipping_ground_delivery: 'label_shipping_ground_delivery',
  label_shipping_freight_delivery: 'label_shipping_freight_delivery',
  label_shipping_express_delivery: 'label_shipping_express_delivery',
  label_shipping_usps_ground_delivery: 'label_shipping_usps_ground_delivery',

  // Labels to start replacing older labels
  label_shipping_EstDelivery: 'label_shipping_EstDelivery', // use for all non-sdd/non-ndd checkout shipping method details
  label_shipping_cutoff_ampm_head: 'label_shipping_cutoff_ampm_head',
  label_shipping_cutoff_ampm_tail: 'label_shipping_cutoff_ampm_tail',
  label_shipping_cutoff_hrsmin_head: 'label_shipping_cutoff_hrsmin_head',
  label_shipping_cutoff_hrsmin_tail: 'label_shipping_cutoff_hrsmin_tail',
  label_shipping_arriveBy: 'label_shipping_arriveby',

  // Common cost related
  label_shipping_from: 'label.configurableSku.startingFrom',

  // Common Use Whole Phrases
  label_shipping_ToolTipLabel: 'label_shipping_HowDoesItWork',
  label_shipping_WhatIsIt: 'label_cart_lineItem_WhatIsIt',

  label_shippingOptions_Delivery: 'label_shippingOptions_Delivery',
  label_shippingOptions_DeliveryDate: 'label_shipping_DeliveryDate',
  label_shippingOptions_EnterAddress: 'label_shipment_options_enter_address',
  label_shipping_itemsShippedFromAutoZone: 'label_shipping_itemsShippedFromAutoZone',
  label_shipping_DeliveryOptions: 'label_shipping_DeliveryOptions',

  // 108 - BOPUS labels
  label_shipping_CheckNearbyStores: 'Label_CheckNearbyStores',
  label_shipping_AtNearByStores: 'Label_AtNearByStores',
  label_shipping_AtNearByStore: 'Label_AtNearByStore',
  label_shipping_SelectStore: 'label_DetailsPage_body_SelectStore',
  label_shipping_VisitStoreToPurchase: 'label_Visit_Store_To_Purchase',

  // 107 - Next Day lables
  label_shipping_NextDayDelivery: 'label.common.nextday.delivery',
  label_shipping_ndd_infoPopper_1: 'labels_order_cut_off_time_head_ndd',
  label_shipping_ndd_freeNddEligible: 'Label_freeNextDayDeliveryEligible',
  lblNDDNotAvailable: 'label_Cart_NextDayDeliveryNotAvailableForRequestedQuantity', // on Cart when QTy exceeded, update to new pattern below
  label_shipping_exceededAvailableQtyAlert: 'label_shipping_exceededAvailableQtyAlert', // use this as tail of message and append the applicable names in front in front
  // 106 - Same Day Labels
  label_shipping_SameDayDelivery: 'label.common.sameday.delivery',
  label_shipping_SameDayEligible: 'label.common.sameday.eligible',
  label_shipping_SameDayPromoExlusionInfoMessage: 'label_shipping_SameDayPromoExlusionInfoMessage',
  label_shipping_SameDay_TextMessageNotificationOptIn:
    'label_shipping_SameDay_TextMessageNotificationOptIn',
  label_shipping_SameDay_PhoneMustBeTextEnabled: 'label_shipping_SameDay_PhoneMustBeTextEnabled',
  // Other Labels
  label_MyAccountSTH_shipaddress_EstDelivery: 'label_MyAccountSTH_shipaddress_EstDelivery',
  label_shipping_checkout_TextNotificationInfoBody1:
    'label_shipping_checkout_TextNotificationInfoBody1',
  label_shipping_checkout_TextNotificationInfoHeader:
    'label_shipping_checkout_TextNotificationInfoHeader',
  label_order_confirmation_shipping_items: 'label_order_confirmation_shipping_items',
  label_order_confirmation_shipping_item: 'label_order_confirmation_shipping_item',
  label_checkout_DeliveryOptions: 'label_checkout_DeliveryOptions',
  label_order_confirmation_arriving_by: 'label_order_confirmation_arriving_by',
  // New Fulfillment Stickers
  label_new_fulfillment_home_delivery_unavailable:
    'label_new_fulfillment_home_delivery_unavailable',
  label_new_fulfillment_35_plus: 'label_new_fulfillment_35_plus',
  label_new_fulfillment_pickup_unavailable_within:
    'label_new_fulfillment_pickup_unavailable_within',
  label_new_fulfillment_for_pickup_availability: 'label_new_fulfillment_for_pickup_availability',
} as const;

export const fulfillmentGroupTypeIds = {
  shipToHome: 101,
  storePickup: 108,
} as const;

export const FULFILLMENT_METHODS = {
  STOREORDER: 108,
  ONLINEORDER: 101,
  NEXTDAY: 107,
  SAMEDAY: 106,
  VDP: 102,
  VDPDS: 103,
  FREIGHT: 104,
  CUSTOMIZEDSKU: 105,
};

export const FULFILLMENT_OPTION_TO_RENDER = {
  NO_STORE_SELECTED: 'noStoreSelected',
  BOPUS_UNAVAILABLE: 'bopusUnavailable',
  BOPUS_UNAVAILABLE_IN_STORE: 'bopusUnavailableInStore',
  BOPUS_AVAILABLE_IN_STORE: 'bopusAvailableInStore',
  NDD_AVAILABLE: 'nddAvailable',
  SDD_DELIVERY: 'sddDelivery',
  STD_DELIVERY: 'stdDelivery',
  STH_UNAVAILABLE: 'sthUnavailable',
};
